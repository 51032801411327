<template>
  <footer class="footer">
      <p>Copyright©2021 M&M Toy Shop</p>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
    width: 100%;
    height: 40px;
    background: #fff;
    line-height: 40px;
    text-align: center;
    position: absolute;
    bottom: 0;
}
.footer p {
    color: #383E45;
}
</style>