<template>
  <div id="report">
    <div class="wrapper-report">
      <div class="wrapper-report-title">
        <h2>แจ้งปัญหา</h2>
      </div>
      <div class="wrapper-report-contact">
        <div class="report-contact-item">
          <v-icon color="#fff">mdi-facebook</v-icon>
          <h1>M&M Toy Shop รับหาของเล่นจากต่างประเทศ</h1>
        </div>
        <div class="report-contact-item">
          <v-icon color="#fff">mdi-phone</v-icon>
          <h1>087 337 2425</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Report",
};
</script>

<style scoped>
.wrapper-report {
  width: 100%;
  height: 180px;
  background: #2c3036;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.wrapper-report-title {
  text-align: right;
  border-right: 1px solid #707070;
  height: 160px;
  line-height: 160px;
}
.wrapper-report-title h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  margin-right: 2.5rem;
}
.wrapper-report-contact {
  padding: 2rem;
  line-height: 3rem;
}
.report-contact-item {
  display: flex;
  align-items: center;
}
.report-contact-item h1 {
  font-size: 20px;
  color: #fff;
  margin-left: 1rem;
  font-weight: 400;
}
@media only screen and (max-width: 1000px) {
  .wrapper-report {
    grid-template-columns: 1fr;
  }
  .wrapper-report-title {
    height: auto;
    line-height: 30px;
    border-right: none;
  }
  .wrapper-report-title {
    text-align: center;
  }
  .wrapper-report-contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .wrapper-report-title h2 {
    margin-right: 0;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 500px) {
    .wrapper-report-title h2 {
    font-size: 24px;
  }
  .report-contact-item h1 {
  font-size: 14px;
  margin-left: .5rem;
}
}
</style>