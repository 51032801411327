<template>
  <div id="home">
    <Appbar />
    <div class="banner">
      <v-img src="@/assets/banner.png" alt="Logo"> </v-img>
    </div>
    <main class="search-tracking">
      <v-container grid-list-xs class="px-4">
        <div class="wrapper-title mb-11">
          <h1>กรอกหมายเลขโทรศัพท์เพื่อค้นหา Tracking Number ของคุณ</h1>
        </div>
        <div class="wrapper-search-input">
          <input
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            placeholder="หมายเลขโทรศัพท์"
            v-model.trim="phoneNumberInput"
          />
          <v-btn
            class="btn-search-tracking ml-4"
            height="90px"
            width="160px"
            color="#fff"
            style="font-size: 24px"
            @click.prevent="submitSearchTracking"
          >
            <v-icon class="icon-magnify pr-2" large>mdi-magnify</v-icon>
            ค้นหา
          </v-btn>
          <div class="hint-message-input">
            กรอกตัวเลขเท่านั้น ตัวอย่าง 099xxxxxxx
          </div>
        </div>
      </v-container>
    </main>
    <Report />
    <Footer />
  </div>
</template>

<script>
import Appbar from "../components/Appbar.vue";
import Report from "../components/Report.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  components: {
    Appbar,
    Report,
    Footer,
  },
  data: () => ({
    phoneNumberInput: "",
  }),
  methods: {
    submitSearchTracking() {
      if (this.phoneNumberInput) {
        this.$router.push("/tracking?phone=" + this.phoneNumberInput);
      }
    },
  },
};
</script>

<style scoped>
.banner {
  margin-top: 100px;
}
@media only screen and (max-width: 550px) {
  .banner {
    margin-top: 80px;
  }
}
.search-tracking {
  margin-top: 4rem;
  margin-bottom: 10rem;
}
.wrapper-title h1 {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
}
.wrapper-search-input {
  display: flex;
  position: relative;
}
.wrapper-search-input input {
  width: 100%;
  background: #383e45;
  border-radius: 4px;
  font-size: 48px;
  padding: 8px 1rem;
  caret-color: #fff;
  color: #fff;
  font-weight: 600;
  border: 1px solid #707070;
  outline: none;
  letter-spacing: 5px;
}
.wrapper-search-input input::placeholder {
  font-weight: 400;
}
.wrapper-search-input input[type="number"]::-webkit-inner-spin-button,
.wrapper-search-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.wrapper-search-input input[type="number"] {
  -moz-appearance: textfield;
}
.hint-message-input {
  color: #fff;
  position: absolute;
  bottom: -2.2rem;
}
@media only screen and (max-width: 815px) {
  .wrapper-title h1 {
    font-size: 24px;
  }
  .wrapper-search-input input {
    font-size: 28px;
    padding: 5px 1rem;
    letter-spacing: 5px;
  }
  .btn-search-tracking {
    font-size: 22px !important;
    height: 60px !important;
    width: 125px !important;
  }
  .icon-magnify {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 500px) {
  .wrapper-title h1 {
    font-size: 24px;
  }
  .wrapper-search-input {
    flex-direction: column;
  }
  .btn-search-tracking {
    font-size: 20px !important;
    height: 40px !important;
    width: 100% !important;
    margin: 3rem 0 0 0 !important;
  }
  .icon-magnify {
    font-size: 22px !important;
  }
  .hint-message-input {
    bottom: 4rem;
    font-size: 13px;
  }
  .search-tracking {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
}
</style>
