<template>
  <v-app-bar fixed dark height="100" class="app-bar">
    <v-container grid-list-xs class="container-navbar">
      <div class="d-flex justify-space-between">
        <v-toolbar-title @click.prevent="$router.push('/')" class="mmtoyshop d-flex align-center">
          <v-img
            src="@/assets/logo.png"
            alt="Logo"
            width="65"
            class="img-logo mx-2 mr-4"
          >
          </v-img>
          M&M Toy Shop Tracking Number
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-list style="background: none">
          <v-list-item>
            <v-list-item-title>
              <v-btn icon color="white" href="https://www.facebook.com/M45Toys" target="_blank">
                <v-icon>mdi-facebook</v-icon>

              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  name: "Appbar",
};
</script>

<style scoped>
.app-bar {
    background: #2C3036 !important;
}
@media only screen and (max-width: 550px) {
  .app-bar {
    height: 80px !important;
  }
  .img-logo {
    width: 45px !important;
  }
  .mmtoyshop {
      font-size: 14px !important;
  }
  .container-navbar {
      height: 80px !important;
      padding: 0 !important;
  }
}
</style>